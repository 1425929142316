<template>
  <v-container fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 sm6 md4 lg4 xl3>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ texts.toobar }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-show="isLoading"
              indeterminate
              color="white"
              width="2"
            ></v-progress-circular>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="email"
                name="email"
                label="Email"
                type="email"
                :error-messages="emailErrors"
                v-model.trim="$v.user.email.$model"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                name="password"
                label="Senha"
                type="password"
                :error-messages="passwordErrors"
                v-model.trim="$v.user.password.$model"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="$v.$invalid"
              color="primary"
              large
              @click="submit"
              >{{ texts.submit }}</v-btn
            >
          </v-card-actions>
          <v-snackbar color="error" v-model="showSnackBar" top>{{
            error
          }}</v-snackbar>
          <v-snackbar color="success" v-model="showSnackBarCreated" top
            >Conta criada com sucesso!</v-snackbar
          >
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import utils from '@/shared/utils';
import { mapActions } from 'vuex';
import { isAuthenticated } from '../services/auth-service';

export default {
  name: 'Login',
  data: () => ({
    error: undefined,
    isLoading: false,
    showSnackBar: false,
    showSnackBarCreated: false,
    user: {
      email: '',
      password: ''
    }
  }),

  created() {
    if (isAuthenticated()) {
      this.$router.push('/dashboard/private-person');
    }
  },

  methods: {
    ...mapActions(['signIn']),

    async submit() {
      try {
        await this.signIn({ user: this.user });
        this.$router.push(this.$route.query.redirect || '/dashboard/private-person');
      } catch (err) {
        this.error = utils.formatError(err.message);
        this.showSnackBar = true;
      } finally {
        this.isLoading = false;
      }
    }
  },

  computed: {
    texts() {
      return { toobar: 'Login', button: 'Criar conta', submit: 'entrar' };
    },

    emailErrors() {
      const errors = [];
      const { email } = this.$v.user;

      if (!email.$dirty) return errors;
      !email.required && errors.push('Email é obrigatório!');
      !email.email && errors.push('Insira um email válido!');

      return errors;
    },

    passwordErrors() {
      const errors = [];
      const { password } = this.$v.user;

      if (!password.$dirty) return errors;
      !password.required && errors.push('Senha é obrigatório!');
      !password.minlength && errors.push(`Insira no mínimo ${password.$params.minlength.min} caracteres!`);

      return errors;
    }
  },

  validations() {
    return {
      user: {
        email: { required, email },
        password: { required, minlength: minLength(6) },
      }
    };
  },
};
</script>
<style>
html {
  overflow-y: auto;
}
</style>
